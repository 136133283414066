@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');

$title-font: 'Signika', sans-serif;
$Heading-font: 'Source Sans Pro', sans-serif;

$background: #323036 !default;
$border-color: #6D6A75 !default;
$text-color: #DBD3D8 !default;
$header-color: #DBD3D8;
$accent-color: #00FDDC;

.root{
    position: absolute;
    bottom: 0px;
    display: block;
    height: 100%
}

.App{
    display: flex;
    flex-direction: column;
    position: absolute;

    width: 100%;
    height: 100%;

    top:0;
    bottom: 0;

    overflow-y: auto;

    background-color: $background;
    color: $text-color;
}

.bar{

    top: 0;
    position: relative;
    display: flex;
    z-index: 1;
    width: 100%;
    height: fit-content;
    color: $background;
    background-color: $header-color;

    min-height: fit-content;
    

    .title{
        flex-grow: 1;

        margin-top: auto;
        margin-bottom: auto;
        margin-right: auto;
        
        margin-left: 25px;
        @media only screen and (max-width: 600px){
            font-size: 25px;
        }
        font-size: 50px;
        font-family: $title-font;
    }

    .information{
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 25px;
    }

    li{
        margin-bottom: 3px;
    }
}

.Body{
    overflow-y: auto;
    .section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-left: 2.5%;
        margin-right: 2.5%;

        max-width: 95%;

        h1{
            font-size: 50px;
            font-style: italic;;
        }

        

        .info{

            @media only screen and (max-width: 600px){
                flex-direction: column;
            }
            display: flex;
            flex-direction: row;

            margin-right: 25px;

            p{
                @media only screen and (max-width: 600px){
                    width: 100%;
                }
                width: 50%
            }
        }
    }
}

.Project{
    
    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .contentBox{
        display: flex;
        align-items: center;
        @media only screen and (max-width: 600px){
            flex-direction: column;
        }
        @media only screen and (min-width: 601px){
            flex-direction: row;
            p{
                width: 500px;
            }

            .oneLine{
                width: fit-content;
                height: fit-content;
                margin-left: 5%;
                margin-right: 0px;
            }
            .column{
                border-right: 0px;
                margin-right: 5px;
            }
        }

        
        
        flex-direction: row;

        margin-bottom: 5px;
    }

    a{
        font-size: larger;
        color: $text-color;
        transition: all .5s linear;
    }

    a:hover{
        color: $accent-color;
        font-size: 64;
    }

    li{
        margin-left: 2px;
    }

    li:hover{
        transition: font-size 0.125s linear;
        color: $accent-color;

        font-size: 105%;
    }
}

.SlideShow{

    position: relative;

    width: fit-content;

    max-height: 500px;

    overflow: hidden;

    .traverse{

        width: 40px;
        background-color: black;

        top: 25px;
        bottom: 25px;

        opacity: .5;
    }

    .traverse:hover{
        opacity: 1;
    }

    .back{
        left: 0px;
        position: absolute;
    }
    .back::after{
        content: '';
        position: absolute;
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent; 
        
        border-right:20px solid white; 

        bottom: calc(50% - 16px);

        left: 3px;
    }


    .forward{
        position: absolute;
        right: 0px;
    }

    .forward::after{
        content: '';
        position: absolute;
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent; 
        
        border-left:20px solid white; 

        margin-left: auto;

        bottom: calc(50% - 20px);
        right: 3px;
    }


    .SlideShowImage{
        @media only screen and (max-width: 1100px){
            width: 100%
        }
        @media only screen and (min-width: 1101px){
            max-height: 500px
        }
    }

    .buttonBox{
        display: flex;
        flex-direction: row;

        align-items: center;
        justify-content: center;

        position: absolute;
        bottom: 10px;

        left: 0;
        right: 0;


    }


    .button{
        z-index: 5;
        position: relative;

        width: 25px;
        height: 25px;

        margin-right: 10px;

        border-radius: 100%;

        opacity: .5;

        background-color: $border-color;

        transition: background-color 1s linear;
        transition: opacity 1s linear;

    }
    .button:hover{
        background-color: $accent-color;
    }
    
}

.description{
    margin-right: 0px;
    max-width: 500px;
}

.Table{
    cursor: default;

    display: flex;
    flex-direction: row;

    justify-content: center;

    border-style: solid;
    border-width: 4px;
    border-color: $border-color;

    width: fit-content;
    margin-top: 25px;

    .column{
        position: relative;
        display: flex;
        flex-direction: column;

        width: 100%;

        border-style: solid;
        
        border-bottom-width: 0px;
        border-left-width: 0;
        border-top-width: 0;

        border-color: $border-color;

        li{
            margin-left: 2px;
        }

        li:hover{
            transition: font-size 0.125s linear;
            color: $accent-color;

            font-size: 105%;
        }
    }

    .columnTitle{
        font-family: $Heading-font;


        border-bottom-width: 4px;
        border-right-width: 0px;
        border-left-width: 0;
        border-top-width: 0;

        border-style: solid;
        border-color: $border-color;

        padding-left: auto;
        padding-right: auto;
    }
}

.Contact{

    @media only screen and (max-width: 600px){
        width: 300px;
    }

    width: 500px;

    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 25px;

    padding: 10px;
    margin-bottom: 15px;

    background-color: $text-color;

    transition: transform 2s;
    overflow: hidden;
    .textBox{
        font-size: x-large;
        color: $background;

        text-align: center;

        transition: transform 1s linear;
        transition-delay: 1s;

        transform: translate(-1000px, 0px);
    }

    button{
        width: 120px;
        height: 60px;
        font-family: $Heading-font;
        font-size: x-large;
        font-style: bold;

        background-color: $border-color;

        border-radius: 15px;

        border-style: none;
        box-shadow: none;
        outline: none;

        transition: background-color 1s ease;

        transition: transform 1s linear;
        transition-delay: 1s;

        transform: translate(1000px, 0px);
    }

    button:hover{
        background-color: $accent-color;
    }
    
}

.Contact.disabled{
    visibility: hidden;
    
}

.Contact.animated{

    visibility: visible;
    
    transform: rotateY(360deg);
    transform: rotateX(360deg);
}

.Footer{
    position: relative;

    bottom: auto;
    p{
        margin-left: 25px;
    }
}
.Reflection{
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    margin-left: 12.5%;
        margin-right: 12.5%;
    .info{
        font-size: 120%;
    }
    .link{
        color: #ffff;
        font-size: 150%;
    }
    .image{
        width:50%;
    }
}