//Animations

@keyframes slideAnimation-out{
    0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(-100%);
      }
}

@keyframes slideAnimation-in{
    0% {
        transform: translateX(100%);
      }
      100% {
        transform: none;
    }
}